<template>
    <div>
        <div class="contact-main">
            <img class="show" src="../static/images/contact/06_jpg1.jpg">
            <div class="bg">
                <div>
                    <p class="title animate__animated animate__fadeInUp">联系我们</p>
                    <div class="content">
                        <ul>
                            <li>
                                <img class="animate__animated animate__fadeInLeft" src="../static/images/index/icon_dizhi.png"/>
                                <div class="animate__animated animate__fadeInRight">
                                    <p>地址</p>
                                    <p>山东省济南市历城区经十东路28666号超算中心科技园2号楼</p>
                                </div>
                                <p class="line"><span></span></p>
                            </li>
                            <li>
                                <img class="animate__animated animate__fadeInLeft" src="../static/images/index/icon_dianhua.png"/>
                                <div class="animate__animated animate__fadeInRight">
                                    <p>电话</p>
                                    <p>400-6767-658</p>
                                </div>
                                <p class="line"><span></span><span class="last"></span></p>
                            </li>
                            <li>
                                <img class="animate__animated animate__fadeInLeft" src="../static/images/index/icon_youxiang.png"/>
                                <div class="animate__animated animate__fadeInRight">
                                    <p>E-MAIL</p>
                                    <p>newenergy@tysen-kld.com</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import foot from "../components/foot";

    export default {
        name: "Contact",
        components: {foot},
        mounted() {
            this.$bus.$emit("headerFixed", false)
        }
    }
</script>

<style lang="less" scoped>
    .contact-main {
        position: relative;
        overflow: hidden;
        min-height: 839px;

        img {
            width: 100%;
        }

        img.show {
            animation-name: changeBiger;
            animation-duration:50s;
            animation-timing-function:linear;
            animation-fill-mode:forwards;
        }

        .bg {
            width: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            margin-left: 0%;
            margin-top: 0%;

            > div {


                .title {
                    font-size: 42px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 5.4%;
                    margin-left: 18.6%;
                }

                .content {
                    margin-left: 29%;
                    margin-top: 5%;

                    ul {
                        li {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 40px 0px;
                            position: relative;

                            div {
                                position: relative;
                                top: 13px;
                                p {

                                    &:first-child {
                                        font-size: 16px;
                                        font-family: PingFang SC;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                    }

                                    &:last-child {
                                        font-size: 10px;
                                        font-family: PingFang SC;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        line-height: 20px;
                                    }
                                }
                            }

                            .line {
                                position: absolute;
                                left: 75px;
                                top: 50%;
                                width: 1px;
                                height: 100%;
                                border-right: 1px dashed rgba(255, 255, 255, 0.2);

                                span {
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    width: 3px;
                                    height: 3px;
                                    border-radius: 50%;
                                    background-color: #FFFFFF;

                                    &.last {
                                        top: unset;
                                        bottom: 0px !important;
                                    }
                                }
                            }

                            img {
                                width: 28px;
                                height: 28px;
                                opacity: 0.4;
                                margin-right: 100px;
                            }

                        }

                    }
                }
            }
        }
    }

    @keyframes changeBiger {
        0% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);

        }
    }
</style>
